import React, { Component } from 'react';
import FormMaker from '../../../components/formMaker';
import { connect } from 'react-redux';
import { storeCurrentRequestId } from '../../../redux/actions';

export class WebDesign extends Component {
    render() {
        return (
            <div>
                <FormMaker formId="602da5e3ba5f15001bb53006" formType="web-designs"/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    current_request_id: state.current_request_id
})

function mapDispatchToProps(dispatch) {
    return {
        storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebDesign)


